import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7ce0299a = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _814dcf18 = () => interopDefault(import('../pages/home/comp_cls.vue' /* webpackChunkName: "pages/home/comp_cls" */))
const _16f892be = () => interopDefault(import('../pages/home/cost_credit.vue' /* webpackChunkName: "pages/home/cost_credit" */))
const _7a2fced8 = () => interopDefault(import('../pages/home/down_files.vue' /* webpackChunkName: "pages/home/down_files" */))
const _245eeadc = () => interopDefault(import('../pages/home/first_prm.vue' /* webpackChunkName: "pages/home/first_prm" */))
const _7a3e79e8 = () => interopDefault(import('../pages/home/home_dash.vue' /* webpackChunkName: "pages/home/home_dash" */))
const _522b5790 = () => interopDefault(import('../pages/home/left_table.vue' /* webpackChunkName: "pages/home/left_table" */))
const _227a9e84 = () => interopDefault(import('../pages/home/map_prm.vue' /* webpackChunkName: "pages/home/map_prm" */))
const _20387d00 = () => interopDefault(import('../pages/home/market_price.vue' /* webpackChunkName: "pages/home/market_price" */))
const _e40cd926 = () => interopDefault(import('../pages/home/mom_prm.vue' /* webpackChunkName: "pages/home/mom_prm" */))
const _ffcc0dae = () => interopDefault(import('../pages/home/near_miss.vue' /* webpackChunkName: "pages/home/near_miss" */))
const _255b441d = () => interopDefault(import('../pages/home/prm_comp.vue' /* webpackChunkName: "pages/home/prm_comp" */))
const _0d3dd465 = () => interopDefault(import('../pages/home/prm_prod.vue' /* webpackChunkName: "pages/home/prm_prod" */))
const _37421444 = () => interopDefault(import('../pages/home/prod_compar.vue' /* webpackChunkName: "pages/home/prod_compar" */))
const _55c8e02b = () => interopDefault(import('../pages/home/prod_time.vue' /* webpackChunkName: "pages/home/prod_time" */))
const _7d7e865e = () => interopDefault(import('../pages/home/quot_ot.vue' /* webpackChunkName: "pages/home/quot_ot" */))
const _c8ab0922 = () => interopDefault(import('../pages/home/quot_prm.vue' /* webpackChunkName: "pages/home/quot_prm" */))
const _223819ef = () => interopDefault(import('../pages/home/rnk_ot.vue' /* webpackChunkName: "pages/home/rnk_ot" */))
const _9fbc8024 = () => interopDefault(import('../pages/home/what_if.vue' /* webpackChunkName: "pages/home/what_if" */))
const _c03ab10c = () => interopDefault(import('../pages/motor/comp_cls.vue' /* webpackChunkName: "pages/motor/comp_cls" */))
const _b16c03cc = () => interopDefault(import('../pages/motor/down_files.vue' /* webpackChunkName: "pages/motor/down_files" */))
const _1e78dc4c = () => interopDefault(import('../pages/motor/first_prm.vue' /* webpackChunkName: "pages/motor/first_prm" */))
const _368d3d16 = () => interopDefault(import('../pages/motor/left_table.vue' /* webpackChunkName: "pages/motor/left_table" */))
const _da2fae10 = () => interopDefault(import('../pages/motor/map_prm.vue' /* webpackChunkName: "pages/motor/map_prm" */))
const _73b71906 = () => interopDefault(import('../pages/motor/market_price.vue' /* webpackChunkName: "pages/motor/market_price" */))
const _321f0ba7 = () => interopDefault(import('../pages/motor/mom_prm.vue' /* webpackChunkName: "pages/motor/mom_prm" */))
const _190ec4c8 = () => interopDefault(import('../pages/motor/motor_dash.vue' /* webpackChunkName: "pages/motor/motor_dash" */))
const _9e7b6a3a = () => interopDefault(import('../pages/motor/near_miss.vue' /* webpackChunkName: "pages/motor/near_miss" */))
const _05e4d323 = () => interopDefault(import('../pages/motor/prm_comp.vue' /* webpackChunkName: "pages/motor/prm_comp" */))
const _2471392a = () => interopDefault(import('../pages/motor/prm_prod.vue' /* webpackChunkName: "pages/motor/prm_prod" */))
const _e78c7dd0 = () => interopDefault(import('../pages/motor/prod_compar.vue' /* webpackChunkName: "pages/motor/prod_compar" */))
const _f31d9c36 = () => interopDefault(import('../pages/motor/prod_time.vue' /* webpackChunkName: "pages/motor/prod_time" */))
const _6566350b = () => interopDefault(import('../pages/motor/quot_ot.vue' /* webpackChunkName: "pages/motor/quot_ot" */))
const _7c340a75 = () => interopDefault(import('../pages/motor/quot_prm.vue' /* webpackChunkName: "pages/motor/quot_prm" */))
const _2fc5b275 = () => interopDefault(import('../pages/motor/rnk_ot.vue' /* webpackChunkName: "pages/motor/rnk_ot" */))
const _54473828 = () => interopDefault(import('../pages/motor/what_if.vue' /* webpackChunkName: "pages/motor/what_if" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/login",
    component: _7ce0299a,
    name: "login___ie"
  }, {
    path: "/home/comp_cls",
    component: _814dcf18,
    name: "home-comp_cls___ie"
  }, {
    path: "/home/cost_credit",
    component: _16f892be,
    name: "home-cost_credit___ie"
  }, {
    path: "/home/down_files",
    component: _7a2fced8,
    name: "home-down_files___ie"
  }, {
    path: "/home/first_prm",
    component: _245eeadc,
    name: "home-first_prm___ie"
  }, {
    path: "/home/home_dash",
    component: _7a3e79e8,
    name: "home-home_dash___ie"
  }, {
    path: "/home/left_table",
    component: _522b5790,
    name: "home-left_table___ie"
  }, {
    path: "/home/map_prm",
    component: _227a9e84,
    name: "home-map_prm___ie"
  }, {
    path: "/home/market_price",
    component: _20387d00,
    name: "home-market_price___ie"
  }, {
    path: "/home/mom_prm",
    component: _e40cd926,
    name: "home-mom_prm___ie"
  }, {
    path: "/home/near_miss",
    component: _ffcc0dae,
    name: "home-near_miss___ie"
  }, {
    path: "/home/prm_comp",
    component: _255b441d,
    name: "home-prm_comp___ie"
  }, {
    path: "/home/prm_prod",
    component: _0d3dd465,
    name: "home-prm_prod___ie"
  }, {
    path: "/home/prod_compar",
    component: _37421444,
    name: "home-prod_compar___ie"
  }, {
    path: "/home/prod_time",
    component: _55c8e02b,
    name: "home-prod_time___ie"
  }, {
    path: "/home/quot_ot",
    component: _7d7e865e,
    name: "home-quot_ot___ie"
  }, {
    path: "/home/quot_prm",
    component: _c8ab0922,
    name: "home-quot_prm___ie"
  }, {
    path: "/home/rnk_ot",
    component: _223819ef,
    name: "home-rnk_ot___ie"
  }, {
    path: "/home/what_if",
    component: _9fbc8024,
    name: "home-what_if___ie"
  }, {
    path: "/motor/comp_cls",
    component: _c03ab10c,
    name: "motor-comp_cls___ie"
  }, {
    path: "/motor/down_files",
    component: _b16c03cc,
    name: "motor-down_files___ie"
  }, {
    path: "/motor/first_prm",
    component: _1e78dc4c,
    name: "motor-first_prm___ie"
  }, {
    path: "/motor/left_table",
    component: _368d3d16,
    name: "motor-left_table___ie"
  }, {
    path: "/motor/map_prm",
    component: _da2fae10,
    name: "motor-map_prm___ie"
  }, {
    path: "/motor/market_price",
    component: _73b71906,
    name: "motor-market_price___ie"
  }, {
    path: "/motor/mom_prm",
    component: _321f0ba7,
    name: "motor-mom_prm___ie"
  }, {
    path: "/motor/motor_dash",
    component: _190ec4c8,
    name: "motor-motor_dash___ie"
  }, {
    path: "/motor/near_miss",
    component: _9e7b6a3a,
    name: "motor-near_miss___ie"
  }, {
    path: "/motor/prm_comp",
    component: _05e4d323,
    name: "motor-prm_comp___ie"
  }, {
    path: "/motor/prm_prod",
    component: _2471392a,
    name: "motor-prm_prod___ie"
  }, {
    path: "/motor/prod_compar",
    component: _e78c7dd0,
    name: "motor-prod_compar___ie"
  }, {
    path: "/motor/prod_time",
    component: _f31d9c36,
    name: "motor-prod_time___ie"
  }, {
    path: "/motor/quot_ot",
    component: _6566350b,
    name: "motor-quot_ot___ie"
  }, {
    path: "/motor/quot_prm",
    component: _7c340a75,
    name: "motor-quot_prm___ie"
  }, {
    path: "/motor/rnk_ot",
    component: _2fc5b275,
    name: "motor-rnk_ot___ie"
  }, {
    path: "/motor/what_if",
    component: _54473828,
    name: "motor-what_if___ie"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
